
const McqComponent = (props: { no:number,data: any, selected: any[], setSelected: any, reset: any }) => {

    const { data, selected, setSelected, reset,no } = props

    const select = (choice: any) => {
        if (selected.includes(choice)) {
            const arr = selected.filter(item => item !== choice)
            setSelected(arr)
        }
        else {
            if (data.type.toLowerCase() === 'single correct') {
                setSelected([choice])
            }
            else {
                setSelected((choices: any) => [choice, ...choices])
            }
        }
    }
    return (
        <div className='bg-white min-h-[70vh] w-full h-auto py-10 px-8 mx-auto my-5 rounded-lg'>

            <div className='flex w-full h-auto items-start justify-between'>

                <div className='text-lg text-highlightText font-montserrat mb-5 '>
                {data.instructions}
                    <p className='text-xs border-highlightText border text-center w-fit px-4 text-highlightText rounded-full'>
                        {data.marks} marks
                    </p>
                </div>
                <button
                    className=' !text-highlightText text-xs !border !border-darkBlue font-montserrat rounded-lg py-2 px-3'
                    onClick={reset}
                >
                    Reset
                </button>
            </div>
            <p className='text-lg text-darkBlue mb-5  font-montserrat'>
            {no}. &nbsp; {data.title}
            </p>
            <p className='text-sm text-darkBlue mb-5  font-montserrat'>
                {data.statement}
            </p>


            <div className=' w-full flex flex-col gap-2'>
                {data?.choices?.map((choice: any, i: number) => {
                    return <div key={`${i}mcqoptions${Math.random()}`} className='w-full flex justify-between items-center text-sm'>
                        {choice}
                        <div onClick={() => { select(choice) }} className={` ${selected.includes(choice) && 'bg-highlightText'} w-[1rem] h-[1rem] rounded-sm border border-inputBorder `}>
                            {selected.includes(choice) && <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                <path d="M11.6673 4.43726L5.25065 10.8539L2.33398 7.93726" stroke="white" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>}
                        </div>

                    </div>
                })}
            </div>

        </div>
    )
}

export default McqComponent
