import React from 'react'

const Heading = (props: { text: string }) => {
    return (
        <div className={`capitalize font-montserrat text-4xl 
        font-bold bg-gradient-to-r from-highlightText  
        to-darkBlue bg-clip-text text-transparent tracking-wider`}>
            {props.text}
        </div>
    )
}

export default Heading
