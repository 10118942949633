import React from 'react'

type buttonTypes = {
    onClick: any,
    children: any,
    classes?: string
}

export const PrimaryButton = (props: buttonTypes) => {
    return (
        <button
            onClick={props.onClick}
            className={`${props.classes} px-9 py-[0.65rem] text-white font-montserrat font-semibold
            text-base bg-highlightText rounded-xl border border-highlightText`}>
            {props.children}
        </button>
    )
}



export const SecondaryButtons = (props: buttonTypes) => {
    return (
        <button
            onClick={props.onClick}
            className={`${props.classes} px-9 py-[0.65rem] text-highlightText font-montserrat font-semibold
            text-base bg-white rounded-xl border border-highlightText`}>
            {props.children}
        </button>
    )
}

