import axios from "axios";


export const postRequest = async(url: string, data: any, onSuccess: (res: any) => any, onFailure: (err: any) => any, headers?: any, params?: {}) => {
    try {
        const response:any=await axios.post(`${url}`,data,{
            headers:headers?headers:{},
            params:params?params:{},
            withCredentials:true,
        })
        onSuccess(response.data)
    } catch (error) {
        onFailure(error)
    }
}