import React, { useEffect, useState } from 'react'
import { DropDownLanguage } from './DropDownLanguage'
import { SecondaryButtons } from './Buttons'
import { Editor } from '@monaco-editor/react'
import { postRequest } from '../ApiFunctions.tsx/post'

const IdeComponent = (props: { runLoaded: boolean, onCompClick: any, language: any, code: string, setCode: any, run: any, output: any, reset: any }) => {
    const { runLoaded, reset, onCompClick, language, code, setCode, run, output } = props
    const [terminal, setTerminal] = useState<boolean>(false)
    useEffect(() => {
        if (runLoaded) {

            setTerminal(true)
        }
    }, [runLoaded])

    return (
        <div className='h-auto w-full bg-white rounded-lg min-h-[70vh]  overflow-hidden pb-3'>
            <div className='w-full h-auto py-[0.8rem] px-3 bg-[#E7E7EC] flex justify-between items-center'>
                <div className='w-[160px] '>
                    <DropDownLanguage
                        classes={'!py-2 !px-3 !text-xs !items-center max-w-[190px]'}
                        componentClasses='!py-2 !px-3 !text-xs !items-center max-w-[190px]'
                        editable={true}
                        displayText={`${language.name.split(' (')[0]}`}
                        onComponentClick={onCompClick} />
                </div>
                <div className='w-fit flex gap-3 items-center'>
                    <svg
                        onClick={reset}
                        className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                        <path d="M17.6498 7.46548C16.9097 6.68618 16.0294 6.06781 15.0597 5.646C14.09 5.22419 13.05 5.0073 11.9998 5.00781C7.57977 5.00781 4.00977 8.75183 4.00977 13.3743C4.00977 17.9968 7.57977 21.7408 11.9998 21.7408C15.7298 21.7408 18.8398 19.074 19.7298 15.4659H17.6498C17.2378 16.689 16.4731 17.7483 15.4611 18.4976C14.4491 19.2469 13.2397 19.6493 11.9998 19.6492C8.68977 19.6492 5.99977 16.836 5.99977 13.3743C5.99977 9.91268 8.68977 7.09944 11.9998 7.09944C13.6598 7.09944 15.1398 7.82105 16.2198 8.96099L12.9998 12.3285H19.9998V5.00781L17.6498 7.46548Z" fill="#01044F" />
                    </svg>
                    <div
                        onClick={run}
                        className=' cursor-pointer rounded-lg border text-darkBlue border-darkBlue py-2 px-3 flex gap-2 items-center'>
                        {runLoaded ? <>
                            <svg
                                className=' animate-spin'
                                viewBox="0 0 1024 1024"
                                fill="currentColor"
                                height="1em"
                                width="1em"
                            >
                                <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z" />
                            </svg>
                        </> : <> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M14.222 7.0617C14.4574 7.1911 14.6538 7.38137 14.7905 7.6126C14.9273 7.84383 14.9994 8.10755 14.9994 8.3762C14.9994 8.64484 14.9273 8.90856 14.7905 9.13979C14.6538 9.37103 14.4574 9.56129 14.222 9.6907L4.222 15.1897C3.99358 15.3151 3.73641 15.3789 3.47585 15.3747C3.21529 15.3705 2.96031 15.2985 2.73604 15.1658C2.51178 15.033 2.32596 14.8442 2.19689 14.6178C2.06783 14.3914 1.99997 14.1353 2 13.8747V2.8767C2.00004 2.61604 2.06801 2.35989 2.1972 2.13351C2.32639 1.90712 2.51234 1.7183 2.73673 1.58567C2.96112 1.45303 3.21619 1.38116 3.47682 1.37713C3.73744 1.3731 3.99462 1.43706 4.223 1.5627L14.222 7.0617Z" fill="#01044F" />
                        </svg>
                            Run
                        </>}
                    </div>
                </div>
            </div>

            <div className='w-full h-full pb-14 relative'>
                <textarea
                    spellCheck={false}
                    style={{ fontFamily: 'monospace' }} className='resize-none h-[62vh] py-2 px-3  w-full  text-sm font-medium focus:outline-none'
                    // language={language.name.split(' (')[0].toLowerCase()}
                    // ordBasedSuggestions={ false}
                    // suggestOnTriggerCharacters= {false}
                    // acceptSuggestionOnEnter= {"off"}
                    // tabCompletion= {"off"}
                    // wordBasedSuggestions= {false}

                    value={code} onChange={(e: any) => {
                        setCode(e.target.value)
                    }}
                />
                <div className={` absolute bottom-14 rounded-lg overflow-hidden bg-white shadow-lg border-t border-inputBorder left-0 w-full h-auto `}>
                    {terminal ? (
                        <div className='h-auto w-full'>
                            <div className='bg-[#E7E7EC] py-3 px-4'>
                                <span className='text-darkBlue text-sm font-manrope '>
                                    Result
                                </span>
                            </div>
                            <div className='py-3 px-5 '>
                                {output ? <>
                                    <div className=' text-xs text-[#5C5D7D] '>
                                        Total Testcases = {output?.totalTestcases}
                                    </div>
                                    <div className=' text-xs text-[#5C5D7D] '>
                                        Testcases Passed=  {output?.passedTestcases}
                                    </div>
                                    <div className=' text-xs text-[#5C5D7D] '>
                                        Testcases Failed=  {output?.totalTestcases - output?.passedTestcases}
                                    </div>
                                </> : <div className='text-xs text-[#5C5D7D]'>
                                    Run the code to see results
                                </div>}
                            </div>
                            <div className='py-4 px-6 w-full h-auto'><div onClick={() => setTerminal(!terminal)} className=' cursor-pointer flex gap-2 items-center text-base font-manrope font-medium text-darkBlue'>
                                Terminal
                                <svg className='rotate-180' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.4114 12.2559C14.7368 12.5814 15.2645 12.5814 15.5899 12.2559C15.9153 11.9305 15.9153 11.4028 15.5899 11.0774L10.5899 6.07741C10.2645 5.75197 9.73683 5.75197 9.4114 6.07741L4.4114 11.0774C4.08596 11.4028 4.08596 11.9305 4.4114 12.2559C4.73683 12.5814 5.26447 12.5814 5.58991 12.2559L10.0007 7.84518L14.4114 12.2559Z" fill="#01044F" />
                                </svg>
                            </div>
                            </div>
                        </div>
                    ) : <div className='py-4 px-6 w-full h-auto'><div onClick={() => setTerminal(!terminal)} className=' cursor-pointer flex gap-2 items-center text-base font-manrope font-medium text-darkBlue'>
                        Terminal
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M14.4114 12.2559C14.7368 12.5814 15.2645 12.5814 15.5899 12.2559C15.9153 11.9305 15.9153 11.4028 15.5899 11.0774L10.5899 6.07741C10.2645 5.75197 9.73683 5.75197 9.4114 6.07741L4.4114 11.0774C4.08596 11.4028 4.08596 11.9305 4.4114 12.2559C4.73683 12.5814 5.26447 12.5814 5.58991 12.2559L10.0007 7.84518L14.4114 12.2559Z" fill="#01044F" />
                        </svg>
                    </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default IdeComponent
