import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { checkUser, logout, returnStorage } from '../components/GlobalConst'
import Question from '../components/Question'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { postRequest } from '../ApiFunctions.tsx/post'

const Exam = () => {
    const navigate = useNavigate()
    const [time, setTime] = useState<string>('')
    const [questions, setQuestions] = useState<any[]>([])
    const [no, setNo] = useState<number>(1)
    const [difference, setDifference] = useState<string>('')
    const [questionList, setQuestionList] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const color = (id: any, i: number) => {
        if (!returnStorage('problem')) {

            if (!returnStorage('mcq')) {
                return i + 1 >= no ? 'bg-[#D8D9DE]' : 'bg-[#EDCFD2]'
            }
            else {
                const array = returnStorage('mcq');
                const index = array.findIndex((item: any) => item.problemId === id);
                if (index !== -1) {
                    return 'bg-[#C6EAD5]'
                }
                else {
                    return i + 1 >= no ? 'bg-[#D8D9DE]' : 'bg-[#EDCFD2]'
                }
            }
        }
        else if (!returnStorage('mcq')) {
            if (!returnStorage('problem')) {
                return i + 1 >= no ? 'bg-[#D8D9DE]' : 'bg-[#EDCFD2]'
            }
            else {
                const array = returnStorage('problem');
                const index = array.findIndex((item: any) => item.problemId === id);
                if (index !== -1) {
                    return 'bg-[#C6EAD5]'
                }
                else {
                    return i + 1 >= no ? 'bg-[#D8D9DE]' : 'bg-[#EDCFD2]'
                }
            }
        }
        else {

            const array = returnStorage('problem');
            const array2 = returnStorage('mcq');
            const index = array.findIndex((item: any) => item.problemId === id);
            const index2 = array2.findIndex((item: any) => item.problemId === id);
            if (index !== -1 || index2 !== -1) {
                return 'bg-[#C6EAD5]'
            }
            else {
                return i + 1 >= no ? 'bg-[#D8D9DE]' : 'bg-[#EDCFD2]'
            }
        }
    }

    const submit = (type?: string) => {
        const obj = {
            problems: returnStorage('problem') ? returnStorage('problem') : [],
            mcqs: returnStorage('mcq') ? returnStorage('mcq') : [],
        }
        if (type === 'force') {
            postRequest(`${process.env.REACT_APP_API}test/submit`, obj, (data: any) => {
                logout()
                navigate('/examination-end')
            }, (err: any) => {
                console.log(err)
            })
            setSubmitted(false)
        }
        else {
            setSubmitted(true)
            // if (window.confirm('Are you sure you want to submit your responses')) {
            //     postRequest(`${process.env.REACT_APP_API}test/submit`, obj, (data: any) => {
            //         logout()
            //         navigate('/examination-end')
            //     }, (err: any) => {
            //         console.log(err)
            //     })
            // }
        }
    }

    useEffect(() => {
        if (!checkUser()) {
            return navigate('/')
        }
        if (!returnStorage('tmere')) {
            return navigate('/')
        }
        else {
            setTime(atob(returnStorage('tmere')))
            const examdata = returnStorage('examdata')
            examdata.problems.map((problem: any, i: number) => {
                if (i === examdata.problems.length - 1) {
                    setQuestions((question) => [problem, ...question])
                    return examdata.mcqs.map((problem: any, i: number) => {
                        return setQuestions((question) => [problem, ...question])
                    })
                }
                else {
                    return setQuestions((question) => [problem, ...question])
                }
            })
        }
    }, [navigate])

    useEffect(() => {
        if (time !== '') {
            const examdata = returnStorage('examdata')
            let int = window.setInterval(() => {
                const remainingTime = new Date(examdata.schedule.end)
                const timeDifference = remainingTime.getTime() - new Date(time).getTime();
                const minutesRemaining = Math.ceil(timeDifference / (1000 * 60))

                const initSeconds = minutesRemaining < examdata.exam.duration ? minutesRemaining * 60 : parseInt(examdata.exam.duration) * 60
                const seconds = initSeconds - (((new Date().getTime() - new Date(time).getTime())) / 1000)
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const remainingSeconds = Math.floor(seconds % 60);

                const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
                setDifference(formattedTime)
                if (seconds <= 0) {
                    submit('force')
                    window.clearInterval(int)
                }
            }, 1000)
        }
    }, [time])
    return (
        <div className='flex overflow-hidden h-screen'>

            <div className={`sm:w-96 w-0 overflow-hidden sm:visible invisible h-full  bg-[#E7E7EC] `}>
                <div className='w-full h-auto py-5 px-6 border-b border-[#a8a8a8] flex justify-between'>
                    <div className=' gap-2 items-center flex text-lg font-montserrat text-highlightText font-semibold text-center '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.75 10.75C8.95914 10.75 10.75 8.95914 10.75 6.75C10.75 4.54086 8.95914 2.75 6.75 2.75C4.54086 2.75 2.75 4.54086 2.75 6.75C2.75 8.95914 4.54086 10.75 6.75 10.75Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.25 21.25C19.4591 21.25 21.25 19.4591 21.25 17.25C21.25 15.0409 19.4591 13.25 17.25 13.25C15.0409 13.25 13.25 15.0409 13.25 17.25C13.25 19.4591 15.0409 21.25 17.25 21.25Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.25 10.75C19.4591 10.75 21.25 8.95914 21.25 6.75C21.25 4.54086 19.4591 2.75 17.25 2.75C15.0409 2.75 13.25 4.54086 13.25 6.75C13.25 8.95914 15.0409 10.75 17.25 10.75Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.75 21.25C8.95914 21.25 10.75 19.4591 10.75 17.25C10.75 15.0409 8.95914 13.25 6.75 13.25C4.54086 13.25 2.75 15.0409 2.75 17.25C2.75 19.4591 4.54086 21.25 6.75 21.25Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Question's List
                    </div>
                    {/* <svg onClick={() => setQuestionList(!questionList)} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                        <path d="M20.5856 6.42376C20.4815 6.31947 20.3579 6.23672 20.2218 6.18027C20.0857 6.12382 19.9398 6.09476 19.7924 6.09476C19.6451 6.09476 19.4992 6.12382 19.3631 6.18027C19.227 6.23672 19.1034 6.31947 18.9993 6.42376L13.4981 11.9138L7.9968 6.41251C7.89265 6.30835 7.769 6.22573 7.63292 6.16936C7.49683 6.113 7.35098 6.08398 7.20368 6.08398C7.05638 6.08398 6.91053 6.113 6.77444 6.16936C6.63836 6.22573 6.51471 6.30835 6.41055 6.41251C6.3064 6.51666 6.22378 6.64031 6.16741 6.7764C6.11104 6.91248 6.08203 7.05834 6.08203 7.20563C6.08203 7.35293 6.11104 7.49878 6.16741 7.63487C6.22378 7.77095 6.3064 7.8946 6.41055 7.99876L11.9118 13.5L6.41055 19.0013C6.3064 19.1054 6.22378 19.2291 6.16741 19.3651C6.11104 19.5012 6.08203 19.6471 6.08203 19.7944C6.08203 19.9417 6.11104 20.0875 6.16741 20.2236C6.22378 20.3597 6.3064 20.4834 6.41055 20.5875C6.51471 20.6917 6.63836 20.7743 6.77444 20.8306C6.91053 20.887 7.05638 20.916 7.20368 20.916C7.35098 20.916 7.49683 20.887 7.63292 20.8306C7.769 20.7743 7.89265 20.6917 7.9968 20.5875L13.4981 15.0863L18.9993 20.5875C19.1035 20.6917 19.2271 20.7743 19.3632 20.8306C19.4993 20.887 19.6451 20.916 19.7924 20.916C19.9397 20.916 20.0856 20.887 20.2217 20.8306C20.3577 20.7743 20.4814 20.6917 20.5856 20.5875C20.6897 20.4834 20.7723 20.3597 20.8287 20.2236C20.8851 20.0875 20.9141 19.9417 20.9141 19.7944C20.9141 19.6471 20.8851 19.5012 20.8287 19.3651C20.7723 19.2291 20.6897 19.1054 20.5856 19.0013L15.0843 13.5L20.5856 7.99876C21.0131 7.57126 21.0131 6.85126 20.5856 6.42376Z" fill="#01044F" />
                    </svg> */}
                </div>

                <div className='w-full h-screen pb-20 overflow-auto flex flex-col'>
                    {questions.map((question: any, i: number) => {
                        return <div onClick={() => setNo(i + 1)}
                            className={` cursor-pointer w-full h-auto p-3 font-poppins font-medium text-base text-darkBlue ${color(question.id, i)}`} key={`${Math.random() + i} questionlist`}>
                            {i + 1}. &nbsp;{question.title}
                        </div>
                    })}
                </div>
            </div>



            <div className='w-full sm:px-24 px-5 pt-14 pb-20 h-auto overflow-auto min-h-screen bg-gradient-to-br from-white to-indigo-100 ' >
                <div className='w-full flex justify-end items-center mb-3'>
                    {/* <div
                        onClick={() => setQuestionList(!questionList)}
                        className='cursor-pointer gap-2 items-center flex text-lg font-montserrat text-highlightText font-semibold text-center '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.75 10.75C8.95914 10.75 10.75 8.95914 10.75 6.75C10.75 4.54086 8.95914 2.75 6.75 2.75C4.54086 2.75 2.75 4.54086 2.75 6.75C2.75 8.95914 4.54086 10.75 6.75 10.75Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.25 21.25C19.4591 21.25 21.25 19.4591 21.25 17.25C21.25 15.0409 19.4591 13.25 17.25 13.25C15.0409 13.25 13.25 15.0409 13.25 17.25C13.25 19.4591 15.0409 21.25 17.25 21.25Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.25 10.75C19.4591 10.75 21.25 8.95914 21.25 6.75C21.25 4.54086 19.4591 2.75 17.25 2.75C15.0409 2.75 13.25 4.54086 13.25 6.75C13.25 8.95914 15.0409 10.75 17.25 10.75Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.75 21.25C8.95914 21.25 10.75 19.4591 10.75 17.25C10.75 15.0409 8.95914 13.25 6.75 13.25C4.54086 13.25 2.75 15.0409 2.75 17.25C2.75 19.4591 4.54086 21.25 6.75 21.25Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Question's List
                    </div> */}
                    <div className='flex gap-3 items-center w-fit'>
                        <div className=' text-lg font-montserrat text-highlightText font-semibold text-center '>
                            {difference}
                        </div>
                        <PrimaryButton onClick={submit}>
                            submit
                        </PrimaryButton>
                    </div>
                </div>

                {questions[0] && <Question

                    no={no}
                    questionno={questions.length}
                    setNo={setNo}
                    data={questions[no - 1]} />}

            </div>



            {submitted && <div className='backdrop-brightness-75 fixed top-0 h-screen w-full flex flex-col justify-center items-center left-0 '>

                <div className='sm:w-1/2 w-[95vw] h-3/4 py-6 sm:px-0 px-0 flex-col bg-white rounded-lg flex justify-center items-center  '>
                    <div className='flex gap-3 mb-3'>
                        <div>
                            <div className='w-5 h-5 rounded-sm border bg-[#C6EAD5]'></div>
                            <div>Attempted</div>
                        </div>
                        <div>
                            <div className='w-5 h-5 rounded-sm border bg-[#edcfd2]'></div>
                            <div>Skipped</div>
                        </div>
                        <div>
                            <div className='w-5 h-5 rounded-sm border bg-[#D8d9de]'></div>
                            <div>Not Attempted</div>
                        </div>
                    </div>
                    <div className={`sm:w-96 w-full h-fit overflow-y-scroll bg-[#E7E7EC] `}>
                        {/* <div className='w-full h-auto py-5 px-6 border-b border-[#a8a8a8] flex justify-between'>
                            <div className=' gap-2 items-center flex text-lg font-montserrat text-highlightText font-semibold text-center '>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6.75 10.75C8.95914 10.75 10.75 8.95914 10.75 6.75C10.75 4.54086 8.95914 2.75 6.75 2.75C4.54086 2.75 2.75 4.54086 2.75 6.75C2.75 8.95914 4.54086 10.75 6.75 10.75Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.25 21.25C19.4591 21.25 21.25 19.4591 21.25 17.25C21.25 15.0409 19.4591 13.25 17.25 13.25C15.0409 13.25 13.25 15.0409 13.25 17.25C13.25 19.4591 15.0409 21.25 17.25 21.25Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.25 10.75C19.4591 10.75 21.25 8.95914 21.25 6.75C21.25 4.54086 19.4591 2.75 17.25 2.75C15.0409 2.75 13.25 4.54086 13.25 6.75C13.25 8.95914 15.0409 10.75 17.25 10.75Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.75 21.25C8.95914 21.25 10.75 19.4591 10.75 17.25C10.75 15.0409 8.95914 13.25 6.75 13.25C4.54086 13.25 2.75 15.0409 2.75 17.25C2.75 19.4591 4.54086 21.25 6.75 21.25Z" stroke="#01044F" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Question's List
                            </div>
                            <svg onClick={() => setQuestionList(!questionList)} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M20.5856 6.42376C20.4815 6.31947 20.3579 6.23672 20.2218 6.18027C20.0857 6.12382 19.9398 6.09476 19.7924 6.09476C19.6451 6.09476 19.4992 6.12382 19.3631 6.18027C19.227 6.23672 19.1034 6.31947 18.9993 6.42376L13.4981 11.9138L7.9968 6.41251C7.89265 6.30835 7.769 6.22573 7.63292 6.16936C7.49683 6.113 7.35098 6.08398 7.20368 6.08398C7.05638 6.08398 6.91053 6.113 6.77444 6.16936C6.63836 6.22573 6.51471 6.30835 6.41055 6.41251C6.3064 6.51666 6.22378 6.64031 6.16741 6.7764C6.11104 6.91248 6.08203 7.05834 6.08203 7.20563C6.08203 7.35293 6.11104 7.49878 6.16741 7.63487C6.22378 7.77095 6.3064 7.8946 6.41055 7.99876L11.9118 13.5L6.41055 19.0013C6.3064 19.1054 6.22378 19.2291 6.16741 19.3651C6.11104 19.5012 6.08203 19.6471 6.08203 19.7944C6.08203 19.9417 6.11104 20.0875 6.16741 20.2236C6.22378 20.3597 6.3064 20.4834 6.41055 20.5875C6.51471 20.6917 6.63836 20.7743 6.77444 20.8306C6.91053 20.887 7.05638 20.916 7.20368 20.916C7.35098 20.916 7.49683 20.887 7.63292 20.8306C7.769 20.7743 7.89265 20.6917 7.9968 20.5875L13.4981 15.0863L18.9993 20.5875C19.1035 20.6917 19.2271 20.7743 19.3632 20.8306C19.4993 20.887 19.6451 20.916 19.7924 20.916C19.9397 20.916 20.0856 20.887 20.2217 20.8306C20.3577 20.7743 20.4814 20.6917 20.5856 20.5875C20.6897 20.4834 20.7723 20.3597 20.8287 20.2236C20.8851 20.0875 20.9141 19.9417 20.9141 19.7944C20.9141 19.6471 20.8851 19.5012 20.8287 19.3651C20.7723 19.2291 20.6897 19.1054 20.5856 19.0013L15.0843 13.5L20.5856 7.99876C21.0131 7.57126 21.0131 6.85126 20.5856 6.42376Z" fill="#01044F" />
                            </svg>
                        </div> */}

                        <div className='w-full h-screen pb-20  flex flex-col'>
                            {questions.map((question: any, i: number) => {
                                return <div
                                    // onClick={() => setNo(i + 1)} 
                                    className={` cursor-pointer w-full h-auto p-3 font-poppins font-medium text-base text-darkBlue ${color(question.id, i)}`} key={`${Math.random() + i} questionlist`}>
                                    {i + 1}. &nbsp;{question.title}
                                </div>
                            })}
                        </div>

                    </div>
                    <div className='w-fit my-5 flex items-center gap-3'>
                        <SecondaryButtons onClick={() => setSubmitted(false)}>
                            Cancel
                        </SecondaryButtons>
                        <PrimaryButton onClick={() => submit('force')}>
                            Submit
                        </PrimaryButton>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Exam
