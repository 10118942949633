import React, { useEffect, useState } from 'react'
import Heading from '../components/Heading'
import { returnStorage } from '../components/GlobalConst'
import { useNavigate } from 'react-router-dom'

const ExaminationEnd = () => {
    const navigate = useNavigate()
    const init = new Date()
    const [seconds, setSeconds] = useState<number>(0)

    useEffect(() => {
        if (!returnStorage('examend')) {
            navigate('/home')
        }
        else {
           let int= window.setInterval(() => {
                const newTime: any = new Date().getTime() - init.getTime()
                if (Math.floor(newTime / 1000) >= 10) {
                    window.clearInterval(int)
                    localStorage.removeItem('examend')
                    navigate('/')
                }
                else {

                    setSeconds(Math.floor(newTime / 1000))
                }

            }, 1000)
        }
    }, [])
    return (
        <div className='w-full p-12 h-auto min-h-screen bg-gradient-to-br from-white to-indigo-100 '>
            <div className='bg-white rounded-lg h-[70vh] w-full flex flex-col justify-center items-center'>
                <Heading text='exam ended' />
                <div className='mt-8 text-base font-montserrat text-textGrey '>
                    Exiting this page in {10-seconds} seconds....
                </div>
            </div>
        </div>
    )
}

export default ExaminationEnd
