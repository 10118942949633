import axios from "axios"


export const getRequest=async(url:string,onSuccess:(res:any)=>any,onFailure:(err:any)=>any,headers?:{},params?:{})=>{
    try {
        const response:any=await axios.get(`${url}`,{
            headers:headers?headers:{},
            params:params?params:{},
            withCredentials:true
        })
        onSuccess(response.data)
    } catch (error) {
        onFailure(error)
    }
}