import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../components/Buttons'
import { postRequest } from '../ApiFunctions.tsx/post'
import { returnStorage } from '../components/GlobalConst'

const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading,setLoading]=useState<boolean>(false)
    function shuffleArray(array: any[]) {
        // Check if the input is a valid array
        if (!Array.isArray(array) || array.length === 0) {
            console.error('Input is not a valid non-empty array.');
            return [];
        }

        // Create a copy of the original array to avoid modifying the input array
        const shuffledArray = array.slice();

        // Fisher-Yates shuffle algorithm
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            // Swap elements at i and j
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        return shuffledArray;
    }
    const submit = () => {
        setLoading(true)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === '' || password === '') {
            window.alert("all fields are necessary")
            setLoading(false)
        }
        else if (!emailRegex.test(email)) {
            window.alert("invalid email")
            setLoading(false)
        }
        else {
            postRequest(process.env.REACT_APP_API + 'test/login',
                {
                    email,
                    password,
                }, (data: any) => {
                    setLoading(false)
                    function setCookie(cname: any, cvalue: any, expires: any) {
                        const d = new Date();
                        d.setTime(d.getTime() + (expires * 24 * 60 * 60 * 1000));
                        const expire = "expires=" + d.toUTCString();
                        document.cookie = cname + "=" + cvalue + ";" + expire + ";path=/";
                    }
                    returnStorage('ended') && localStorage.removeItem('ended');
                    const dataExam = data
                    if (dataExam.msg === 'Test started') {



                        const msg = dataExam.msg
                        const schedule = dataExam.schedule
                        const exam = dataExam.exam
                        const problems = shuffleArray(dataExam.problems)
                        const mcqs = shuffleArray(dataExam.mcqs)
                        const dataExamNew = {
                            msg,
                            schedule,
                            exam,
                            problems,
                            mcqs
                        }
                        localStorage.setItem('examdata', JSON.stringify(dataExamNew))
                        setCookie("lgdinstu", btoa('true'), 1);
                        navigate('/home')
                    }
                    else {
                        localStorage.setItem('examdata', JSON.stringify(data))
                        setCookie("lgdinstu", btoa('true'), 1);
                        navigate('/home')
                    }
                }, (err: any) => {
                    setLoading(false)
                    err.response.data.message ? window.alert(err.response.data.message) : window.alert('Login failed')
                }
            )
        }
    }
    useEffect(() => {
        document.cookie.includes('lgdin') && navigate('/home')
        if (returnStorage('tmere')) {
            navigate('/exam')
        }
    }, [])

    return (
        <div className='h-screen w-full overflow-clip relative px-0  sm:px-[6.5vw] py-[11vh] '>
            <div className='flex  h-full w-[70vw] mx-auto border bg-white rounded-lg relative z-10 overflow-clip'>
                <div className='w-fit h-full sm:block hidden'>
                    <img src={'./assets/Image.png'} className='w-auto h-full' alt={'banner'} />
                </div>
                <div className='w-auto h-full py-10 px-2 sm:px-10'>
                    <div className='w-fit h-full flex justify-between items-center flex-col'>
                        <div className='font-montserrat whitespace-pre-wrap text-center sm:whitespace-nowrap w-fit mx-auto font-bold text-[44px] text-darkBlue '>
                            Welcome to Edslash
                        </div>
                        <div className='sm:w-auto w-full h-auto flex gap-3 flex-col'>
                            <div className='bg-whitesky border border-inputBorder rounded-lg flex justify-center items-center px-3'>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#9a9a9a"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6m-2 0l-8 5-8-5h16m0 12H4V8l8 5 8-5v10z" />
                                </svg>
                                <input value={email} onChange={(e: any) => setEmail(e.target.value)} type="Email" placeholder="Email" className='sm:w-[28.63vw] w-max text-base py-3 px-3 rounded-lg bg-whitesky focus:outline-none ' />
                            </div>


                            <div className='bg-whitesky border  border-inputBorder rounded-lg flex justify-center items-center px-3'>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#9a9a9a"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10l.002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z" />
                                </svg>
                                <input value={password} onChange={(e: any) => setPassword(e.target.value)} type="password" placeholder="Password" className='sm:w-[28.63vw] w-max text-base py-3 px-3 rounded-lg bg-whitesky focus:outline-none ' />
                            </div>


                        </div>
                        <div className='w-fit mx-auto'>
                            <PrimaryButton classes='!rounded-full' onClick={submit}>
                                {loading?'signing in...':'Sign in'}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
            <span className="z-20 h-[31vw] w-[31vw] rounded-full absolute left-[-10.5vw] bottom-[-31vh] bg-gradient-to-tr  from-highlightText to-[#2575FC00] " ></span>
            <span className=" h-[52vh] w-[46vw] rotate-[-42.54deg]  absolute right-[-29.48vw] top-[-27vh] bg-gradient-to-bl  from-highlightText to-[#2575FC00] " ></span>
        </div>
    )
}

export default Login

