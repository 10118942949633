import React, { useState } from 'react'
import { languagesArr } from './GlobalConst'

type dropdownTypes = {
    onComponentClick: any,
    classes?: string,
    componentClasses?: string,
    displayText: string,
    editable: boolean
}
export const DropDownLanguage = (props: dropdownTypes) => {
    const [dropDown, setdropDown] = useState<boolean>(false)
    const dropDownClick = () => {
        props.editable && setdropDown(!dropDown)
    }
    return (
        <>
            <div
                onClick={dropDownClick}
                className={`${props.classes} px-6 py-5 text-base text-darkBlue 
                font-medium font-poppins border relative ${dropDown ? ' rounded-t-xl' : 'rounded-xl '} 
                border-highlightText flex justify-between bg-white `}>
                <span className='capitalize max-w-[10rem] truncate'>
                    {props.displayText}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.5886 7.74408C5.26317 7.41864 4.73553 7.41864 4.41009 7.74408C4.08466 8.06951 4.08466 8.59715 4.41009 8.92259L9.41009 13.9226C9.73553 14.248 10.2632 14.248 10.5886 13.9226L15.5886 8.92259C15.914 8.59715 15.914 8.06951 15.5886 7.74408C15.2632 7.41864 14.7355 7.41864 14.4101 7.74408L9.99935 12.1548L5.5886 7.74408Z"
                        fill="currentColor" />
                </svg>
                <div className={`top-16 left-0 capitalize z-10 rounded-b-xl absolute min-w-full  border-highlightText ${dropDown ? 'h-auto max-h-64 overflow-y-scroll border' : 'h-0 overflow-hidden'} `}>
                    {languagesArr.map((value: {id: number,name: string}, i: number) => {
                        return <DropDownComponent key={`${value.id}languagesmake${Math.floor(Math.random() * 1000)} `}
                            text={value}
                            click={props.onComponentClick}
                            classes={props.componentClasses}
                        />
                    })}
                </div>
            </div>
        </>
    )
}


const DropDownComponent = (props: { click: any, text: any, classes?: any }) => {
    return (
        <>
            <div
                onClick={() => props.click(props.text)}
                className={`${props.classes} px-6 py-5 text-base text-darkBlue 
                font-medium font-poppins bg-white
                flex justify-between`}
            >
                {props.text.name.split(' (')[0]}
            </div>
        </>
    )
}

