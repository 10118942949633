import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRequest } from '../ApiFunctions.tsx/get'
import { DropDownLanguage } from './DropDownLanguage'
import IdeComponent from './IdeComponent'
import { languagesArr, returnStorage } from './GlobalConst'
import { postRequest } from '../ApiFunctions.tsx/post'
import McqComponent from './McqComponent'
import { PrimaryButton, SecondaryButtons } from './Buttons'

const Question = (props: { data: any, no: number, setNo: any, questionno: number }) => {
    const { data, no, setNo, questionno } = props
    const navigate = useNavigate()
    const [type, setType] = useState('problem')
    const [boilerplate, setBoilerplate] = useState<any[]>([])
    const [language, setLanguage] = useState(languagesArr[0])
    const [code, setCode] = useState('')
    const [output, setOutput] = useState()
    const [selected, setSelected] = useState<any[]>([])
    const [loadSave, setSave] = useState<boolean>(false)
    const [runLoaded, setRun] = useState<boolean>(false)
    const reset = () => {

        if (type === 'problem') {
            setCode('')
            const index = boilerplate.findIndex((val) => { return val.language_id === language.id })

            index !== -1 && setCode(atob(boilerplate[index].code))

            if (returnStorage('problem')) {
                const array = returnStorage('problem');
                const index = array.findIndex((item: any) => item.problemId === data.id);
                // =====================if the user has saved a response for the same coding question before,this will delete the response======================
                if (index !== -1) {
                    array.splice(index, 1)
                    return localStorage.setItem('problem', JSON.stringify(array));
                }
            }
        }
        else {
            setSelected([])
            if (returnStorage('mcq')) {
                const array = returnStorage('mcq');
                const index = array.findIndex((item: any) => item.problemId === data.id);
                // =====================if the user has saved a response for the same coding question before,this will delete the response======================
                if (index !== -1) {
                    array.splice(index, 1)
                    return localStorage.setItem('mcq', JSON.stringify(array));
                }
            }
        }
    }
    // =============================this function saves responses and edits them if already present in localstorage=================================
    const saveAndNext = () => {
        setSave(true)
        let int = setInterval(() => {
            setSave(false)
            window.clearInterval(int)
        }, 800)
        // =============if the question type is coding this block will be executed=================
        if (type === 'problem') {
            if (code === '') {
                return next()
            }
            const obj = {
                language_id: language.id,
                problemId: data.id,
                solution: btoa(code)
            }
            // =========if the user has never saved a response for coding question before,this will run=========================
            if (!returnStorage('problem')) {
                localStorage.setItem('problem', JSON.stringify([obj]));
                return next()
            }
            // if the user has saved a response for coding question
            else {
                const array = returnStorage('problem');
                const index = array.findIndex((item: any) => item.problemId === data.id);
                // =====================if the user has saved a response for the same coding question before,this will edit the response======================
                if (index !== -1) {
                    array[index] = obj
                    localStorage.setItem('problem', JSON.stringify(array));
                    return next()
                }
                // ===============if user is saving a response for this coding question for the first time================================
                else {
                    array.push(obj);
                    localStorage.setItem('problem', JSON.stringify(array));
                    return next()
                }
            }
        }
        // =============if the question type is MCQ this block will be executed=================
        else {
            if (!selected[0]) {
                return next()
            }
            const obj = {
                problemId: data.id,
                markedChoices: selected
            }
            // =========if the user has never saved a response for MCQ before,this will run=========================
            if (!returnStorage('mcq')) {
                localStorage.setItem('mcq', JSON.stringify([obj]));
                return next()
            }
            // if the user has saved a response for MCQ
            else {
                const array = returnStorage('mcq');
                const index = array.findIndex((item: any) => item.problemId === data.id);
                // =====================if the user has saved a response for the same MCQ before,this will edit the response======================
                if (index !== -1) {
                    array[index] = obj
                    localStorage.setItem('mcq', JSON.stringify(array));
                    return next()
                }
                // ===============if user is saving a response for this MCQ for the first time================================
                else {
                    array.push(obj);
                    localStorage.setItem('mcq', JSON.stringify(array));
                    return next()
                }
            }
        }
    }

    // ==================this function is called to get nxet question====================
    const next = () => {
        if (no === questionno) {
            return;
        }
        else {
            return setNo(no + 1)
        }
    }
    // =======================this function is called to get previous question=============
    const previous = () => {
        if (no === 1) {
            return;
        }
        else {
            return setNo(no - 1)
        }
    }

    // ======================this function is called when user wants to execute a code in the coding question================================
    const run = () => {
        setRun(true)
        const api = `${process.env.REACT_APP_API}test/problem/execute`
        postRequest(api, {
            problemId: data.id,
            solution: btoa(code),
            language_id: language.id
        }, (data) => {
            setOutput(data)
            setRun(false)
        }, (err) => {
            console.log(err)
            setRun(false)
        })
    }



    const onCompClick = (data: any) => {
        setLanguage(data)
    }





    useEffect(() => {
        // setLanguage(languagesArr[0])
        if (data.desc) {
            setType('problem')
            if (returnStorage('problem')) {
                const array = returnStorage('problem');
                const index = array.findIndex((item: any) => item.problemId === data.id);
                if (index !== -1) {
                    const indexLang = languagesArr.findIndex((item) => item.id === array[index].language_id)
                    // const indexLang = languagesArr.findIndex((item: any) => item.id === array[index].language_id);
                    if (indexLang !== -1) {
                        setLanguage(languagesArr[indexLang])
                    }
                    else {
                        setLanguage(languagesArr[0])
                    }
                }
            }
            else {
                setLanguage(languagesArr[0])
            }
        }
        else {
            setType('mcq')
        }
    }, [data.desc])


    useEffect(() => {
        if (type === 'problem') {

            getRequest(`${process.env.REACT_APP_API}problem/${data.id}/boilerplates`, (data) => {
                setBoilerplate(data)

            }, (err) => {
                console.log(err)
            })
        }
        else {
            setSelected([])
            if (returnStorage('mcq')) {
                const array = returnStorage('mcq')
                const index = array.findIndex((item: any) => item.problemId === data.id);
                // =====================if the user has saved a response for the same MCQ before,this will edit the response======================
                if (index !== -1) {
                    return setSelected(array[index].markedChoices)
                }
            }
        }
    }, [data.id, type])

    useEffect(() => {
        if (type === 'problem') {
            if (returnStorage('problem')) {
                // if the user has saved a response for MCQ
                const array = returnStorage('problem');
                const index = array.findIndex((item: any) => item.problemId === data.id);
                // =====================if the user has saved a response for the same MCQ before,this will edit the response======================
                if (index !== -1) {
                    const indexLang = language.id === array[index].language_id
                    // const indexLang = languagesArr.findIndex((item: any) => item.id === array[index].language_id);
                    if (indexLang) {
                        return setCode(atob(array[index].solution))
                    }
                    else {
                        setCode('')
                        const index = boilerplate.findIndex((val) => { return val.language_id === language.id })
                        if (index !== -1) {
                            return setCode(atob(boilerplate[index].code))
                        }
                    }
                }
                else {
                    setCode('')
                    const index = boilerplate.findIndex((val) => { return val.language_id === language.id })

                    if (index !== -1) {
                        return setCode(atob(boilerplate[index].code))
                    }
                }
            }
            else {
                setCode('')
                const index = boilerplate.findIndex((val) => { return val.language_id === language.id })

                if (index !== -1) {
                    return setCode(atob(boilerplate[index].code))
                }
            }
        }
    }, [language])


    return (
        <>

            <div className='h-auto  w-full flex gap-3'>
                {type === 'problem' ? <div className='flex gap-3 w-full h-auto sm:flex-row flex-col'>
                    <div className='h-auto w-full bg-white rounded-lg min-h-[60vh] sm:min-h-[70vh] overflow-hidden pb-8'>
                        <div className='w-full h-auto py-5 px-3 bg-[#E7E7EC] flex justify-between items-center'>
                            <p className='w-max text-ellipsis whitespace-nowrap font-manrope text-lg font-semibold text-darkBlue'>
                                {no}. &nbsp; {
                                    data.title
                                }</p>
                        </div>
                        <textarea disabled value={type === 'problem' ? data.desc : data.statement}
                            className='px-8 py-9 w-full h-full overflow-y-auto bg-white resize-none text-textGrey font-manrope text-sm font-medium capitalize'>
                        </textarea>
                    </div>
                    <IdeComponent
                        runLoaded={runLoaded}
                        reset={reset}
                        code={code}
                        setCode={setCode}
                        language={language} onCompClick={onCompClick}
                        run={run}
                        output={output} />
                </div> : (type === 'mcq' && <>
                    <McqComponent
                        no={no}
                        reset={reset}
                        selected={selected}
                        setSelected={setSelected}
                        data={data} />
                </>)}
            </div>
            <div className='w-full h-auto '>
                <div className='w-fit flex sm:flex-row flex-col gap-4 items-center h-auto mx-auto mt-4'>
                    <div className='flex gap-4 w-fit'>
                        {no !== 1 && <SecondaryButtons onClick={previous}>
                            Previous
                        </SecondaryButtons>}
                        {no !== questionno && <SecondaryButtons onClick={next}>
                            Skip
                        </SecondaryButtons>}
                    </div>
                    <PrimaryButton onClick={saveAndNext}>
                        {loadSave ? <>
                            <svg
                                className=' animate-spin'
                                viewBox="0 0 1024 1024"
                                fill="currentColor"
                                height="1em"
                                width="1em"
                            >
                                <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z" />
                            </svg>
                        </> : (no !== questionno ? 'Save and Next' : 'Save')}
                    </PrimaryButton>
                </div>
            </div>
        </>
    )
}

export default Question
