import React, { Suspense, lazy, useEffect, useState } from 'react'
import { checkUser, returnStorage } from '../components/GlobalConst'
import { useNavigate } from 'react-router-dom'
const ExamStatus = lazy(() => import('../components/ExamStatus'))

const LandingPage = () => {
    const navigate = useNavigate()
    const [examdata, setExamdata] = useState<any>({ msg: '' })
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (!checkUser()) {
            navigate('/')
        }
        if (returnStorage('tmere')) {
            navigate('/exam')
        }
        else {
            if (returnStorage('examdata')) {
                setExamdata(returnStorage('examdata'))
                setLoading(false)
            }

        }
    }, [])


    return (
        <div className='w-full p-12 h-auto min-h-screen bg-gradient-to-br from-white to-indigo-100 '>
            {loading ? <>
                <div className='bg-slate-200 h-screen w-full animate-pulse flex flex-col gap-3 px-2 sm:px-20'>
                    <div className='w-full h-10 bg-slate-600 rounded-xl'>

                    </div>
                    <div className='w-full h-3/4 bg-slate-600 rounded-xl'>

                    </div>
                    <div className='w-full h-10 bg-slate-600 rounded-xl'>

                    </div>
                </div>
            </> : <Suspense fallback={<div className='bg-slate-200 h-screen w-full animate-pulse flex flex-col gap-3 px-2 sm:px-20'>
                <div className='w-full h-10 bg-slate-600 rounded-xl'>

                </div>
                <div className='w-full h-3/4 bg-slate-600 rounded-xl'>

                </div>
                <div className='w-full h-10 bg-slate-600 rounded-xl'>

                </div>
            </div>} >
                <ExamStatus examData={examdata} />
            </Suspense>}
        </div>
    )
}

export default LandingPage
