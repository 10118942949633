import { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import Exam from './pages/Exam';
import ExaminationEnd from './pages/ExaminationEnd';
function App() {


  useEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  return (
    <div className='font-manrope text-textBlack'>
      <Router>
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/home' element={<LandingPage/>}/>
          <Route path='/exam' element={<Exam/>}/>
          <Route path='/examination-end' element={<ExaminationEnd/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
